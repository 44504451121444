import styled, { keyframes } from 'styled-components';
import { themeGet } from 'styled-system';

const shake = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;


export const TermsWrapper = styled.div`
  background-color: ${themeGet('colors.paletteGreen')};
  padding: 100px 15px 50px 15px;
  display: flex;
  align-items: center;
`;

export const TermsContent = styled.div`

  font-size: 15px;
  line-height: 18px;

  max-width: 800px;
  width: 100%;
  padding: 0px 15px;
  margin: 0 auto;

  p {
    color: ${themeGet('colors.paletteBlack')};
  }

  a {
    color: ${themeGet('colors.paletteBlack')};
    text-decoration: underline;
  }

  h2 {
    margin-top: 50px;
  }

  li {
    list-style-type: lower-latin;
    list-style-position: inside;
    display: list-item;
    margin-bottom: 16px;
    line-height: 22px;
  }
  
  .image-wrapper {
    @media (max-width: 1480px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    margin-top: 25px;
    margin-bottom: 54px;
  }

  .reusecore__button {
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    &:first-child {
      margin-right: 20px;
      &:hover {
        opacity: 0.95;
      }
    }

    &:hover {
      .btn-icon {
        animation: ${shake} 1s infinite;
      }
    }
  }
`;


