import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { TermsWrapper, TermsContent } from './terms.style';

const Terms = ({ title, text }) => {

  const data = useStaticQuery(graphql`
    query Terms {
      allMarkdownRemark(filter: {frontmatter: {title: {eq: "terms"}}}) {
        edges {
          node {
            id
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const termsHTML = data.allMarkdownRemark.edges[0].node.html;

  return (
    <TermsWrapper>
      <TermsContent>
        <div
          {...text}
          dangerouslySetInnerHTML={{__html: `<div>${termsHTML}</div>`}}
        />
      </TermsContent>
    </TermsWrapper>
  );
};

Terms.propTypes = {
  imageWrapper: PropTypes.object,
};

Terms.defaultProps = {
  imageWrapper: {
    width: '200px',
    mt: '40px',
    mb: '40px',
  },
  title: {
  },
  text: {
    color: '#000000',
  },
  homeButton: {
    type: 'button',
    color: '#000000',
  },
};

export default Terms;
